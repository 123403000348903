<template>
	<div>
		<a-modal title="订购套餐" :width="540" :visible="visible"  @cancel="handleCancel" :footer="null">
			
			<a-spin :spinning="loading">
				<div>
					<a-radio-group v-model="select_type" button-style="solid" @change="changeType">
						<a-radio-button :value="'weixin'">
							微信支付
						</a-radio-button>
						<a-radio-button :value="'activation_code'">
							激活码支付
						</a-radio-button>
					</a-radio-group>
				</div>
				<div class="mt20 pd20_40">
					<div v-if="select_type=='weixin'">
						<div v-if="isPaidAct == false">
							<div class="ft14 ftw500 cl-info">选择套餐</div>
							<div class="mt12 ml60">
								<div class="flex wrap">
									<template v-for="(item,index) in datas">
										<div class="renew-taocan-item active" v-if="select_index==index">
											<span >{{item.info}}</span>
											<div  class="flex center renew-taocan-item-popover" v-if="item.discount != 10">
												<div class="tag-tit-addquestion">{{item.discount}}折</div>
											</div>
										</div>
										<div class="renew-taocan-item clickAct" v-else @click="selectIt(index)">
											<span >{{item.info}}</span>
											<div  class="flex center renew-taocan-item-popover" v-if="item.discount != 10">
												<div class="tag-tit-addquestion">{{item.discount}}折</div>
											</div>
										</div>
									</template>
								</div>
							</div>
							
							<div class="mt16">
								<div class="flex alcenter">
									<div class="ft14 ftw500 cl-info">价格：</div>
									<div class="ft24 ftw500 cl-price">¥{{price}}</div>
								</div>
							</div>
							<div class="mt16 flex center">
								<a-button @click="weixinPayAct" type="primary">立即续费</a-button>
							</div>
						</div>
						
						<div v-else>
							<div class="flex center ">
								<div class="renew_type">
									<div class="flex center alcenter" style="height: 100%;">
										<i class="iconfont iconwepay ft18 " style="color: #4AB728;"/>
										<div class="ml8 ft14 ftw500 cl-black">微信支付</div>
									</div>
								</div>
							</div>
							
							<div class="mt16">
								<div class="flex center">
									<vue-qr  :text="codeUrl" :size="200"></vue-qr>
								</div>
								<div class="flex center mt16">
									<div class="ft12 ftwblod text-center cl-info">使用微信扫码支付</div>
								</div>
							</div>
						</div>
					</div>
				
					<div v-if="select_type=='activation_code'" class="pd20">
						<div class="flex center alcenter">
							<div>
								<a-input style="width: 280px;" placeholder="请输入激活码" v-model="form.activation_code"  />
							</div>
							<div>
								<a-button :loading="confirmLoading"  type="primary" @click="activate()">激活</a-button>
							</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
    import vueQr from 'vue-qr';
	export default{
		components: {
		  vueQr
		},
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data(){
			return {
				loading: false,
				confirmLoading:false,
				select_index:0,
				select_type:'weixin',
				form:{
					activation_code:'',
				},
				isPaidAct:false,
				datas:[],
				codeUrl:'111',
				orderId:0,
				timer:null,
			}
		},
		destroyed(){
			this.clearTimer();
		},
		created() {
			if(this.select_type=='weixin'){
				this.loaddata();
			}
		},
		computed:{
			price(){
				return (this.datas.length>0) ? this.datas[this.select_index].price : 0;
			}
		},
		
		methods: {
			clearTimer(){
				if(this.timer != null){
					clearInterval(this.timer);
					this.timer = null;
				}
			},
			checkTimer(){
				this.timer = setInterval(()=>{
					this.$http.papi('platform/paymentStatus',{
						order_id:this.orderId
					}).then(res=>{
						if(res.status == 1){
							this.$message.success('支付成功');
							this.handleCancel();
							this.refreshPage('/index'); //返回首页
						}
					}).catch(res=>{
						console.log(res);
					});
				},2000);
			},
			weixinPayAct(){
				this.loading = true;
				this.$http.papi('platform/appOrder',{
					skuIndex:this.select_index,
				}).then(res=>{
					this.loading = false;
					this.isPaidAct = true;
					this.orderId = res.order_id;
					this.codeUrl = res.code_url;
					this.checkTimer();
				}).catch(res=>{
					this.loading = false;
				});
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showAppSku').then(res=>{
					this.datas=JSON.parse(JSON.stringify(res.sku));
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			selectIt(index){
				this.select_index=index;
			},
			
			changeType(e){
				this.clearTimer();
				this.isPaidAct = false;
				if(e.target.value=='weixin'){
					this.loaddata();
				}
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.clearTimer();
				this.$emit("cancel");
			},
			
			activate(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.papi('platform/terminal/activateShop',{
					activation_code:this.form.activation_code,
				}).then(res=>{
					this.$message.success('激活成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			}
			
		}
		
	}
</script>

<style>
	.renew-taocan-item{
		padding: 4px 16px 4px 16px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		
		font-size: 16px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
		margin-bottom: 10px;
		position: relative;
	}
	
	.renew-taocan-item.active{
		border: 1px solid #4772FF;
		color: #4772FF;
	}
	
	.renew-taocan-item-popover{
		position: absolute;
		top:-28px; 
		left: 0px; 
		width: 100%;
	}
	
	.renew_type{
		width: 124px;
		height: 48px;
		background: #FFFFFF;
		border-radius: 24px;
		border: 1px solid #EBEDF5;
	}
	
	.renew_pay_qrcode{
		width: 140px;
		height: 140px;
	}
	
	.tag-tit-addquestion{
		/* width: 36px; */
		height: 20px;
		background: #FF6600;
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		padding: 0 8px;
		line-height: 20px;
		border-radius: 4px;
		position: relative;
	}
	
	.tag-tit-addquestion::before{
		content: "";
		width: 0px;
		height: 0px;
		border-top: 6px solid #FF6600;
		border-right: 6px solid transparent;
		position: absolute;
		left: calc(50% - 3px); 
		bottom: -2px;
		
		transform:rotate(-135deg);
		-ms-transform:rotate(-135deg); 	/* IE 9 */
		-moz-transform:rotate(-135deg); 	/* Firefox */
		-webkit-transform:rotate(-135deg); /* Safari 和 Chrome */
		-o-transform:rotate(-135deg); 	/* Opera */
	}
	
	.renew-taocan-activation-code-input .ant-input {
	    height: 60px;
	    padding: 4px 20px;
	    color: rgba(0, 0, 0, 0.65);
	   font-size: 18px;
	   font-weight: 500;
	    line-height: 1.5;
	    background-color: #fff;
	    background-image: none;
	    border: 1px solid #EBEDF5;
	    border-radius: 2px;
	    transition: all 0.3s;
	}
	
	.renew-taocan-activation-code-button{
		width: 480px;
		height: 60px;
		background: #4772FF;
		border-radius: 4px;
		
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		
		line-height: 60px;
		text-align: center;
	}

</style>