<template>
    <div>
        <div class="flex">
            <div style="width: 100%;">
                <a-row>
                    <a-col :span="20">
                        <a-col :span="6">
                            <div class="count-box">
                                <div class="flex alcenter">
                                    <i class="iconfont icondata01 ft32" style="color:#33A0FD;"></i>
                                    <span class="ft18 cl-main ftw600 ml15">工单数 </span>
                                </div>
                                <div class="mt24 text-center ft16 cl-info">
                                    累计
                                </div>
                                <div class="mt16 text-center cl-black ft30 ftw600"
                                     @click="goToPages('/appointment/order')">{{ order.total_num }}
                                </div>
                                <div class="flex" style="margin-top: 42px;">
                                    <div class="col2 text-center" @click="goToPages('/appointment/order?inquire=toDay')">
                                        <div class="ft16 cl-info">今日新增</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ order.today_num }}</div>
                                    </div>
                                    <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;" @click="goToPages('/appointment/order?inquire=thisMonth')">
                                        <div class="ft16 cl-info">近30天新增</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ order.month_num }}</div>
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="6">
                            <div class="count-box">
                                <div class="flex alcenter">
                                    <i class="iconfont icondata02 ft32" style="color:#00C3A0;"></i>
                                    <span class="ft18 cl-main ftw600 ml15">长者用户数</span>
                                </div>
                                <div class="mt24 text-center ft16 cl-info">
                                    累计
                                </div>
                                <div class="mt16 text-center cl-black ft30 ftw600"
                                     @click="goToPages('/memberAdmin/elder')">{{ elderly.total_num }}
                                </div>
                                <div class="flex" style="margin-top: 42px;">
                                    <div class="col2 text-center" @click="goToPages('/memberAdmin/elder?inquire=toDay')">
                                        <div class="ft16 cl-info">今日新增</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ elderly.today_num }}</div>
                                    </div>
                                    <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;" @click="goToPages('/memberAdmin/elder?inquire=thisMonth')">
                                        <div class="ft16 cl-info">近30天新增</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ elderly.month_num }}</div>
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="6">
                            <div class="count-box">
                                <div class="flex alcenter">
                                    <i class="iconfont icondata031 ft32" style="color:#FEC75A;"></i>
                                    <span class="ft18 cl-main ftw600 ml15">随访数</span>
                                </div>
                                <div class="mt24 text-center ft16 cl-info">
                                    累计
                                </div>
                                <div class="mt16 text-center cl-black ft30 ftw600" @click="goToPages('/member/access')">
                                    {{ access.total_num }}
                                </div>
                                <div class="flex" style="margin-top: 42px;">
                                    <div class="col2 text-center" @click="goToPages('/member/access?inquire=toDay')">
                                        <div class="ft16 cl-info">今日</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ access.today_num }}</div>
                                    </div>
                                    <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;" @click="goToPages('/member/access?inquire=thisMonth')">
                                        <div class="ft16 cl-info">近30天</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ access.month_num }}</div>
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="6">
                            <div class="count-box">
                                <div class="flex alcenter">
                                    <i class="iconfont icondata031 ft32" style="color:#FEC75A;"></i>
                                    <span class="ft18 cl-main ftw600 ml15">站点数据</span>
                                </div>
                                <div class="mt24 text-center ft16 cl-info">
                                    站点总数
                                </div>
                                <div class="mt16 text-center cl-black ft30 ftw600"
                                     @click="goToPages('/siteAdmin/manage')">{{ site_num }}
                                </div>
                                <div class="flex" style="margin-top: 42px;">
                                    <div class="col2 text-center" @click="goToPages('/siteAdmin/user')">
                                        <div class="ft16 cl-info">服务人员总数</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ ServicePersonnel.total_num }}</div>
                                    </div>
                                    <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;" @click="goToPages('/memberAdmin/elder')">
                                        <div class="ft16 cl-info">长者总数</div>
                                        <div class="ft18 mt8 ftw600 cl-black">{{ elderly.total_num }}</div>
                                    </div>
                                </div>
                            </div>
                        </a-col>
                    </a-col>
                    <a-col :span="4">
                        <div class="kefu-box ml20" style="border-radius:0px;">
                            <div class="kefu-box-top" style="padding:0px;">
                                <div class="text-center ifram-box">
                                    <img src="@/assets/image/ping.png"
                                         style="height:100%; width:100%;margin:0;border:0;"/>
                                </div>
                                <div class="text-center ft14 cl-info">
                                    <div class="ft14 ftw600 cl-theme mt10">国叶健康智慧康养驾驶舱</div>
                                    <div class="ft14 ftw600 cl-theme" style="margin-top:30px;">
                                        <a-button type="primary" size="small" @click="openUrl">全屏预览</a-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        site_num: {
            type: Number,
            default: 0
        },
        order: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        },
        elderly: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        },
        ServicePersonnel: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        },
        access: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        goToPages(uri) {
            this.refreshPage(uri);
        },
        openUrl() {
            var target = "https://cockpit.gourdcare.com/#/";
            let newwindow = window.open("", "", "scrollbars")
            if (document.all) {
                newwindow.moveTo(0, 0)
                newwindow.resizeTo(screen.width, screen.height)
            }
            newwindow.location = target;
        },
    }
}
</script>

<style>
.count-box {
    width: 100%;
    height: 260px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
}

.count-box div {
    line-height: 1;
}

.ftw600 {
    cursor: pointer;
}

.kefu-box {
    width: 220px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    line-height: 1;
}

.kefu-box-top {
    width: 100%;
    height: 188px;
    padding: 20px 20px 16px 20px;
}

.kefu-box-bottom {
    width: 100%;
    height: 72px;
    position: relative;
}

.kefu-box-bottom .main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 72px;
    padding: 15px 20px;
}

.kefu-box-bottom .bg {
    width: 100%;
    height: 82px;
}
</style>
